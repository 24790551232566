<template>
  <div class="page error-page">
    <ImagesSvg icon="warning" class="error-page__warning" width="352" height="397" />
    <div class="error-page__content">
      <ImagesSvg :icon="errorPage.icon" width="109" height="140" class="error-page__icon" />
      <h1 class="error-page__title">{{ errorPage.title }}</h1>
      <p class="error-page__text">
        {{ errorPage.message }}
      </p>
      <ButtonsBaseButton type="button" @click="handleError">
        {{ contentStore.content?.backToHome ?? 'Terug naar het startscherm' }}
      </ButtonsBaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NuxtError } from '#app';
import { storeToRefs } from 'pinia';
import type { Icon } from '@/types/icon';

interface ErrorPage {
  title: string;
  message: string;
  icon: Icon;
}

definePageMeta({
  layout: 'empty',
});
const { getContent } = useContentApi();

const store = useGeneralStore();
const { loading } = storeToRefs(store);

const props = defineProps<{ error: NuxtError }>();

const { data } = await useAsyncData('content', () => getContent());

const contentStore = useContentStore();
contentStore.content = data.value;

function handleError() {
  loading.value = false;
  clearError({ redirect: routes.home.path });
}

const errorPage = computed<ErrorPage>(() => {
  if (props.error.statusCode === 404) {
    if (contentStore.content){
      return {
        title: contentStore.content.title404 ?? '',
        message: contentStore.content.message404 ?? '',
        icon: 'hammer',
      };
    }

    return {
      title: 'Oeps, hier slaan we de plank even mis!',
      message: 'We hebben je de verkeerde kant op gestuurd of moeten deze pagina nog bouwen.',
      icon: 'hammer',
    };
  }

  if (props.error.statusCode === 503){
    return {
      title: 'We zijn druk aan het klussen',
        message: 'Daarom is onze app tijdelijk niet beschikbaar. Probeer het later nog eens.',
        icon: 'close',
    };
  }

  if (props.error.statusCode >= 500 && props.error.statusCode < 600) {
    if (contentStore.content){
      return {
        title: contentStore.content.title500,
        message: contentStore.content.message500,
        icon: 'close',
      };
    }

    return {
      title: 'Oeps, er is iets misgegaan!',
      message: 'Er is iets misgegaan, probeer het later opnieuw.',
      icon: 'close',
    };
  }

  return {
    title: props.error.statusCode.toString() ?? '',
    message: props.error.statusMessage ?? '',
    icon: 'close',
  };
});
</script>

<style lang="scss" scoped>
.error-page {
  background-color: $c-green-3;
  color: $c-white;

  &__warning {
    position: absolute;
    right: 0;
    top: -6%;
    opacity: 0.1;
  }

  &__content {
    text-align: center;
    display: grid;
    padding: px(16);
    margin-top: px(180);
  }

  &__icon {
    margin-inline: auto;
  }

  &__title {
    margin-bottom: px(16);
    margin-top: px(24);
  }

  &__text {
    margin-bottom: px(24);
  }
}
</style>
