/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.47
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpeningTimeApiModel
 */
export interface OpeningTimeApiModel {
    /**
     * 
     * @type {string}
     * @memberof OpeningTimeApiModel
     */
    day?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OpeningTimeApiModel
     */
    openFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OpeningTimeApiModel
     */
    openUntil?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OpeningTimeApiModel
     */
    closed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OpeningTimeApiModel
     */
    currentDay?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OpeningTimeApiModel
     */
    specialHoliday?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof OpeningTimeApiModel
     */
    specialHolidayStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OpeningTimeApiModel
     */
    specialHolidayEnd?: Date | null;
}

/**
 * Check if a given object implements the OpeningTimeApiModel interface.
 */
export function instanceOfOpeningTimeApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OpeningTimeApiModelFromJSON(json: any): OpeningTimeApiModel {
    return OpeningTimeApiModelFromJSONTyped(json, false);
}

export function OpeningTimeApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpeningTimeApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'openFrom': !exists(json, 'openFrom') ? undefined : json['openFrom'],
        'openUntil': !exists(json, 'openUntil') ? undefined : json['openUntil'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'currentDay': !exists(json, 'currentDay') ? undefined : json['currentDay'],
        'specialHoliday': !exists(json, 'specialHoliday') ? undefined : json['specialHoliday'],
        'specialHolidayStart': !exists(json, 'specialHolidayStart') ? undefined : (json['specialHolidayStart'] === null ? null : new Date(json['specialHolidayStart'])),
        'specialHolidayEnd': !exists(json, 'specialHolidayEnd') ? undefined : (json['specialHolidayEnd'] === null ? null : new Date(json['specialHolidayEnd'])),
    };
}

export function OpeningTimeApiModelToJSON(value?: OpeningTimeApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'openFrom': value.openFrom,
        'openUntil': value.openUntil,
        'closed': value.closed,
        'currentDay': value.currentDay,
        'specialHoliday': value.specialHoliday,
        'specialHolidayStart': value.specialHolidayStart === undefined ? undefined : (value.specialHolidayStart === null ? null : value.specialHolidayStart.toISOString()),
        'specialHolidayEnd': value.specialHolidayEnd === undefined ? undefined : (value.specialHolidayEnd === null ? null : value.specialHolidayEnd.toISOString()),
    };
}

