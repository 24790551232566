/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.47
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderDetailProductApiModelDeliveryTime = {
    Unknown: 'Unknown',
    Direct: 'Direct',
    Delayed: 'Delayed',
    NoDelivery: 'NoDelivery',
    StoreOnly: 'StoreOnly'
} as const;
export type OrderDetailProductApiModelDeliveryTime = typeof OrderDetailProductApiModelDeliveryTime[keyof typeof OrderDetailProductApiModelDeliveryTime];


export function OrderDetailProductApiModelDeliveryTimeFromJSON(json: any): OrderDetailProductApiModelDeliveryTime {
    return OrderDetailProductApiModelDeliveryTimeFromJSONTyped(json, false);
}

export function OrderDetailProductApiModelDeliveryTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailProductApiModelDeliveryTime {
    return json as OrderDetailProductApiModelDeliveryTime;
}

export function OrderDetailProductApiModelDeliveryTimeToJSON(value?: OrderDetailProductApiModelDeliveryTime | null): any {
    return value as any;
}

